.news-not-found-error{
    background-color: #f5f6;
    height: 100vh !important;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333;
}
.news-not-found-error h1{
     font-size: 3.8rem;
     margin: 25px 0;
     text-transform: capitalize;
 }
.news-not-found-error h2{
    font-size: 2rem;
    margin: 25px 0;
}
.news-not-found-error .go-back-buttons{
    margin: 45px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}
.news-not-found-error .go-back-buttons a{
    text-decoration: none;
    padding: 10px 18px;
    margin:  0 16px;
    font-weight: 500;
    border-radius: 6px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.103);
    background: #fff;
    color: #ff156f;
}
@media(max-width:450px){
    .news-not-found-error .go-back-buttons a{
        margin: 0 5px;
    }
    .news-not-found-error .go-back-buttons {
        justify-content: space-between;
    }
}
@media(max-width:420px){
    .news-not-found-error .go-back-buttons a{
        margin: 10px 5px;
        width: 100%;
        text-align: center;
    }
    .news-not-found-error .go-back-buttons {
        flex-direction: column;
    }
    .news-not-found-error h1{
        font-size: 3rem;
    }
}