.footer{
    background: #f4f4f4;
    padding: 12px 0;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
}
.footer .com{
    flex: 1;
    margin: 0 5vw;
    padding: 20px;
}
@media(max-width:900px){
    .footer .com{
        margin: 0 3.5vw;
    }
}
@media(max-width:600px){
    .footer .com{
        margin: 0 0 !important;
    }
}
/* Samvaad css */
.footer .samvaad h1{
    margin-bottom: 10px;
}
.footer .samvaad p{
    margin: 15px 0;
    font-weight: 500;
    color: #555;
}
.footer .samvaad h3{
    font-size: 25px;
    font-weight: 500;
    margin: 10px 0;
}
.footer .samvaad .social{
    display: flex;
}
.footer .samvaad .social i{
    padding: 10px 13px;
    margin: 5px;
    font-size: 18px;
    border-radius: 50%;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.062);
    color: #fff;
    cursor: pointer;
}
.footer .samvaad .social .fa-facebook-f{
    background: #3b5998;
}
.footer .samvaad .social .fa-instagram{
    background: #d6249f; background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}
.footer .samvaad .social .fa-linkedin-in{
    background-color: #0e76a8;
}
.footer .samvaad .social .fa-whatsapp{
    background-color: #25D366;
}

/* Samvaad css */

/* usefull links css */

.footer .usefullinks{
    display: flex;
    flex-direction: column;
}
.footer .usefullinks h1{
    margin-bottom: 10px;
}
.footer .usefullinks .links{
    flex: 1;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.footer .usefullinks .links a{
    font-weight: 700;
    text-decoration: none;
    color: #444;
}
.footer .usefullinks .links i{
    color: #6f00ff;
}
/* usefull links css */

/* contact us css */
.footer .contactus{
    display: flex;
    flex-direction: column;
}
.footer .contactus h1{
    margin-bottom: 10px;
}
.footer .contactus .contacts{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.footer .contactus .contacts p{
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
}
.footer .contactus .contacts i{
    color: #6f00ff;
    font-size: 22px;
    padding: 5px;
}
/* contact us css */


/* Footer Bottom Copyright */
.copyright{
    background-color: #6f00ff;
    color: white;
    text-align: center;
    padding: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}
.copyright a{
    color: white;
    text-decoration: none;
}
/* Footer Bottom Copyright */


/* Responsive Footer */
@media(max-width:710px){
    .footer{
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .footer .com{
        min-height: 200px;
        flex: unset !important;
    }
    .footer .samvaad{
        flex: 1;
    }
    .footer .com h1{
        font-size: 2.1rem;
    }
}
@media(max-width:500px){
    .footer .com{
        padding: 15px 10px;
    }
}
/* Responsive Footer */