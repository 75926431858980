.HomeParent .section1{
    min-height: 92vh;
    /* background-color: #f865; Maybe used in future */
    padding: 0 125px;
    overflow-x: hidden;
    display: flex;
    justify-content: space-around;
}
/* Text .Section1  */
.HomeParent .section1 .text{
    padding: 15px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.HomeParent .section1 .text p{
    color: #6f00ff;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 10px;
    font-weight: 600;
    margin: 0;
}
.HomeParent .section1 .text h1{
    color: #333;
    font-size: 3.2rem;
    font-weight: 700;
}
.HomeParent .section1 .text h5{
    color: #444;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2.2rem;
}

/* Button .sECTION1  */
.button-container{
    margin-top: 25px;
    padding: 10px;
}
.button-container a{
    color: white;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 15px;
    padding: 10px 16px;
    border-radius: 6px;
}
.button-container a:nth-child(1){
    background-color: white;
    color: #6f00ff;
    border: 1.4px solid #6f00ff;
}
.button-container a:nth-child(2){
    background-color: #6f00ff;
    border: 1.4px solid transparent;
}
/* Button .sECTION1  */


/* Text .Section1  */



/* Image .Section1 */
.HomeParent .section1 .image{
    /* background-color: #00ff; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.HomeParent .section1 .image img{
    height: 500px;
}
/* Image .Section1 */

/*Responsive Part*/
@media(max-width:1250px){
    .HomeParent .section1{
        padding: 0 75px;
    }
    .HomeParent .section1 .image img{
        height: 400px;
    }
}
@media(max-width:1050px){
    .HomeParent .section1{
        padding: 0 15px;
    }
    .HomeParent .section1 .image img{
        height: 400px;
    }
}
@media(max-width:900px){
    .HomeParent .section1 .image img{
        height: 350px;
    }
}
@media(max-width:825px){
    .HomeParent .section1{
        min-height: 75vh;
    }
    .HomeParent .section1 .image{
        display: none;
    }
}
@media(max-width:600px){
    .HomeParent .section1{
        padding: 0 0;
    }
}
@media(max-width:540px){
    .HomeParent .section1{
        min-height: 90vh;
    }
    .HomeParent .section1 .text h1{
        font-size: 2.5rem;
    }
}
@media(max-width:400px){
    .HomeParent .section1 {
        min-height: unset;
    }
    .HomeParent .section1 .text{
        justify-content: flex-start;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .HomeParent .section1 .text p{
        margin-bottom: 15px;
    }
    .HomeParent .section1 .text .button-container{
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
    }
    .HomeParent .section1 .text .button-container a{
        font-size: 18px;
        margin: 0;
    }
    .HomeParent .section1 .text h1{
        font-size: 2.45rem;
    }
}
@media(max-width:365px){
    .HomeParent .section1 .text .button-container{
        flex-direction: column;
    }
    .HomeParent .section1 .text .button-container a{
        margin-bottom: 12px;
        text-align: center;
    }
}
/*Responsive Part*/