.Newscard {
  height: 400px;
  width: 300px;
  margin: 25px 16px;
  border-radius: 5px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.185);
  overflow: hidden;
}
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.55rem;
  border-radius: 4px;
}
.date-loaded{
  margin-top: 5px 0;
}
.author {
    width: 30%;
    height: 0.9rem;
    margin-bottom: 15px;
    font-weight: 600;
    min-height: 21.6px;
}
.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
.Newscard .img-container p {
  position: absolute;
  font-size: 10px;
  background-color: #ff156f;
  z-index: 1;
  color: white;
  border-top-left-radius: 5px;
  padding: 4px 6px;
}

.Newscard .img-container{
  height: 225px;
}
.Newscard .img-container img {
  width: 100%;
  height: 225px;
  animation: skeleton 1s linear infinite alternate;
}
.Newscard .info {
  padding: 10px;
}
.Newscard .info h3 {
  font-size: 18px;
  font-weight: 500;
}
.loading{
  cursor: default !important;
}

/* Loaded CSS */
.Newscard .img-container img{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.author-loaded{
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 21.6px;
  color: #666;
  font-weight: 700;
  overflow: hidden;
  margin-bottom: 10px;
}
.info .title-loaded{
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 22px;
  overflow: hidden;
  font-weight: 500;
  height: 64.8px;
}
.readmore{
  background: #ff156f;
  padding: 6px 12px;
  color: white;
  font-weight: 500;
  margin-left: 1.1rem;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
  text-decoration: none;
  outline: none;
  border: none;
}
/* Loaded CSS */

/* responsive css */
@media(max-width:680px){
  .Newscard{
    height: 400px;
    width: 300px;
    margin: 12px 16px;
    border-radius: 5px;
  }
}
/* Breaks Here */
@media(max-width:665px){
  .Newscard{
    min-width: 90%;
    flex: 1;
    margin: 12px 16px;
    padding-bottom: 15px;
    border-radius: 5px;
  }   
  .readmore{
    padding: 8px 16px;
    color: white;
    font-weight: 500;
    margin-left: 1.1rem;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    margin-top: 10px;
    text-decoration: none;
  }
  .Newscard .img-container{
    min-height: 220px;
  }
  .Newscard .img-container img {
    min-height: 220px;
  }
}
/* responsive css */
