@import url(https://fonts.googleapis.com/css?family=Exo:400,700);
.HomeParent .section1{
    min-height: 92vh;
    /* background-color: #f865; Maybe used in future */
    padding: 0 125px;
    overflow-x: hidden;
    display: flex;
    justify-content: space-around;
}
/* Text .Section1  */
.HomeParent .section1 .text{
    padding: 15px;
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.HomeParent .section1 .text p{
    color: #6f00ff;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 10px;
    font-weight: 600;
    margin: 0;
}
.HomeParent .section1 .text h1{
    color: #333;
    font-size: 3.2rem;
    font-weight: 700;
}
.HomeParent .section1 .text h5{
    color: #444;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2.2rem;
}

/* Button .sECTION1  */
.button-container{
    margin-top: 25px;
    padding: 10px;
}
.button-container a{
    color: white;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 15px;
    padding: 10px 16px;
    border-radius: 6px;
}
.button-container a:nth-child(1){
    background-color: white;
    color: #6f00ff;
    border: 1.4px solid #6f00ff;
}
.button-container a:nth-child(2){
    background-color: #6f00ff;
    border: 1.4px solid transparent;
}
/* Button .sECTION1  */


/* Text .Section1  */



/* Image .Section1 */
.HomeParent .section1 .image{
    /* background-color: #00ff; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
}
.HomeParent .section1 .image img{
    height: 500px;
}
/* Image .Section1 */

/*Responsive Part*/
@media(max-width:1250px){
    .HomeParent .section1{
        padding: 0 75px;
    }
    .HomeParent .section1 .image img{
        height: 400px;
    }
}
@media(max-width:1050px){
    .HomeParent .section1{
        padding: 0 15px;
    }
    .HomeParent .section1 .image img{
        height: 400px;
    }
}
@media(max-width:900px){
    .HomeParent .section1 .image img{
        height: 350px;
    }
}
@media(max-width:825px){
    .HomeParent .section1{
        min-height: 75vh;
    }
    .HomeParent .section1 .image{
        display: none;
    }
}
@media(max-width:600px){
    .HomeParent .section1{
        padding: 0 0;
    }
}
@media(max-width:540px){
    .HomeParent .section1{
        min-height: 90vh;
    }
    .HomeParent .section1 .text h1{
        font-size: 2.5rem;
    }
}
@media(max-width:400px){
    .HomeParent .section1 {
        min-height: unset;
    }
    .HomeParent .section1 .text{
        justify-content: flex-start;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .HomeParent .section1 .text p{
        margin-bottom: 15px;
    }
    .HomeParent .section1 .text .button-container{
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
    }
    .HomeParent .section1 .text .button-container a{
        font-size: 18px;
        margin: 0;
    }
    .HomeParent .section1 .text h1{
        font-size: 2.45rem;
    }
}
@media(max-width:365px){
    .HomeParent .section1 .text .button-container{
        flex-direction: column;
    }
    .HomeParent .section1 .text .button-container a{
        margin-bottom: 12px;
        text-align: center;
    }
}
/*Responsive Part*/
.section2{
    min-height: 90vh;
    /* background-color: #ff45; Maybe used in future*/
    padding: 0 125px;
    display: flex;
}
.section2 .how, .steps{
    flex: 1 1;
}
/* How Portion */
.section2 .how{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.allblock{
    display: block;
    color: #6f00ff;
}
.section2 .how h1{
    font-size: 4rem;
    color: #333;
}
.section2 .how p{
    font-size: 18px;
    line-height: 35px;
    font-weight: 600;
    color: #444;
}
.section2 .how .button-container{
    margin-top: 40px;
}
.section2 .how a{
    padding: 15px 30px;
    color: white;
    background-color: #6f00ff;
}
/* How Portion */

/* Steps Portion */
.section2 .steps{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.section2 .steps .row{
    display: flex;
    margin: 20px 0;
}
.section2 .steps .row .step{
    margin: 0 10px;
    padding: 30px 22px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.13);
}
.section2 .steps .row .step span{
    font-size: 21px;
    padding: 8px 16px;
    border-radius: 7px;
    font-weight: 600;
    background: #6f00ff;
    color: white;
}
.section2 .steps .row .step h2{
    font-size: 22px;
    font-weight: 700;
    margin: 18px 0;
}
.section2 .steps .row .step h4{
    color: #666;
    font-weight: 600;
}
/* Steps Portion */

/* Responsive portion */
@media(max-width:1100px){
    .section2{
        padding: 0 50px;
    }
    .section2 .steps .row{
        margin: 0;
        flex-direction: column;
    }
    .section2 .steps .row .step{
        margin: 10px 10px;
    }
}
@media(max-width:800px){
    .section2{
        padding: 0 0;
    }
}
@media(max-width:600px){
    .section2{
        flex-direction: column;
    }       
    .section2 .how {
        min-height: 275px;
    }
    .section2 .how .button-container a{
        padding: 10px 20px;
        margin: 15px 0;
    }
    .section2 .how h1{
        font-size: 2.5rem;
    }
    .section2 .how p{
        margin: 20px 0;
        margin-bottom: 0;
    }       
    .section2 .how,.section2 .steps{
        padding: 15px;
    }
    .section2 .steps .row{
        margin: 0;
        flex-direction: row;
    }
}
@media(max-width:450px){
    .section2 .steps .row .step{
        padding: 22px 12px;
    }
}
@media(max-width:410px){
    .section2 .steps .row{
        margin: 0;
        flex-direction: column;
    }   
    .section2 .steps .row .step{
        margin: 14px 8px;
    }
}
/* Responsive portion */
.navbar{
    margin: 17px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.navbar a{
    text-decoration: none;
}
.navbar .brand{
    font-size: 26px;
    color: #333;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 10px 15px;
}
.navbar #bartimetoggler{
    font-size: 32px;
    display: none;
}
.navbar .nav-links a{
    color: rgb(0,26,56);
    padding: 13px 20px;
    margin: 6px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.6px;
    font-weight: 600;
}
.navbar .nav-links a:hover{
    color: #6f00ff;
    transition: 0.5s;
}

#contribute{
    background-color: #6f00ff;
    color: white;
    border-radius: 5px;
}
#mob{
    display: none;
}
/* Responsive portion */
@media(max-width:860px){
    #pc{
        display: none;
    }
    #mob{
        display: flex;
    }
    .navbar{
        margin: 0;
        padding: 7px 0;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.076);
    }
    .navbar #bartimetoggler{
        display: block;
        font-size: 32px;
        padding: 10px 15px;
        color: #6f00ff;
    }
    .navbar .nav-links{
        width: 100%;
        background-color: #6f00ff;
        padding: 55px 0;
        position: absolute;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        top: 67px;
        text-align: center;
        transition: 0.5s;
        -webkit-animation: nav-anime 0.4s linear;
                animation: nav-anime 0.4s linear;
        overflow-y: hidden;
        z-index: 1000;
        min-height: 400px;
    }
    .navbar .nav-links a{
        color: white;
        font-weight: 600;
        font-size: 22px;
    }
    .navbar .nav-links a:hover{
        color: azure !important;
    }
    @-webkit-keyframes nav-anime{
        0%{
            left: -70vw;
            opacity: 0.5;
        }
        100%{
            left: 0;
            top: 67px;
            opacity: 1;
        }
    }
    @keyframes nav-anime{
        0%{
            left: -70vw;
            opacity: 0.5;
        }
        100%{
            left: 0;
            top: 67px;
            opacity: 1;
        }
    }
}

.footer{
    background: #f4f4f4;
    padding: 12px 0;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
}
.footer .com{
    flex: 1 1;
    margin: 0 5vw;
    padding: 20px;
}
@media(max-width:900px){
    .footer .com{
        margin: 0 3.5vw;
    }
}
@media(max-width:600px){
    .footer .com{
        margin: 0 0 !important;
    }
}
/* Samvaad css */
.footer .samvaad h1{
    margin-bottom: 10px;
}
.footer .samvaad p{
    margin: 15px 0;
    font-weight: 500;
    color: #555;
}
.footer .samvaad h3{
    font-size: 25px;
    font-weight: 500;
    margin: 10px 0;
}
.footer .samvaad .social{
    display: flex;
}
.footer .samvaad .social i{
    padding: 10px 13px;
    margin: 5px;
    font-size: 18px;
    border-radius: 50%;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.062);
    color: #fff;
    cursor: pointer;
}
.footer .samvaad .social .fa-facebook-f{
    background: #3b5998;
}
.footer .samvaad .social .fa-instagram{
    background: #d6249f; background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}
.footer .samvaad .social .fa-linkedin-in{
    background-color: #0e76a8;
}
.footer .samvaad .social .fa-whatsapp{
    background-color: #25D366;
}

/* Samvaad css */

/* usefull links css */

.footer .usefullinks{
    display: flex;
    flex-direction: column;
}
.footer .usefullinks h1{
    margin-bottom: 10px;
}
.footer .usefullinks .links{
    flex: 1 1;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.footer .usefullinks .links a{
    font-weight: 700;
    text-decoration: none;
    color: #444;
}
.footer .usefullinks .links i{
    color: #6f00ff;
}
/* usefull links css */

/* contact us css */
.footer .contactus{
    display: flex;
    flex-direction: column;
}
.footer .contactus h1{
    margin-bottom: 10px;
}
.footer .contactus .contacts{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.footer .contactus .contacts p{
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
}
.footer .contactus .contacts i{
    color: #6f00ff;
    font-size: 22px;
    padding: 5px;
}
/* contact us css */


/* Footer Bottom Copyright */
.copyright{
    background-color: #6f00ff;
    color: white;
    text-align: center;
    padding: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}
.copyright a{
    color: white;
    text-decoration: none;
}
/* Footer Bottom Copyright */


/* Responsive Footer */
@media(max-width:710px){
    .footer{
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .footer .com{
        min-height: 200px;
        flex: unset !important;
    }
    .footer .samvaad{
        flex: 1 1;
    }
    .footer .com h1{
        font-size: 2.1rem;
    }
}
@media(max-width:500px){
    .footer .com{
        padding: 15px 10px;
    }
}
/* Responsive Footer */
.Newscard {
  height: 400px;
  width: 300px;
  margin: 25px 16px;
  border-radius: 5px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.185);
  overflow: hidden;
}
.skeleton {
  opacity: 0.7;
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
          animation: skeleton-loading 1s linear infinite alternate;
}

@-webkit-keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.55rem;
  border-radius: 4px;
}
.date-loaded{
  margin-top: 5px 0;
}
.author {
    width: 30%;
    height: 0.9rem;
    margin-bottom: 15px;
    font-weight: 600;
    min-height: 21.6px;
}
.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
.Newscard .img-container p {
  position: absolute;
  font-size: 10px;
  background-color: #ff156f;
  z-index: 1;
  color: white;
  border-top-left-radius: 5px;
  padding: 4px 6px;
}

.Newscard .img-container{
  height: 225px;
}
.Newscard .img-container img {
  width: 100%;
  height: 225px;
  -webkit-animation: skeleton 1s linear infinite alternate;
          animation: skeleton 1s linear infinite alternate;
}
.Newscard .info {
  padding: 10px;
}
.Newscard .info h3 {
  font-size: 18px;
  font-weight: 500;
}
.loading{
  cursor: default !important;
}

/* Loaded CSS */
.Newscard .img-container img{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.author-loaded{
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 21.6px;
  color: #666;
  font-weight: 700;
  overflow: hidden;
  margin-bottom: 10px;
}
.info .title-loaded{
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 22px;
  overflow: hidden;
  font-weight: 500;
  height: 64.8px;
}
.readmore{
  background: #ff156f;
  padding: 6px 12px;
  color: white;
  font-weight: 500;
  margin-left: 1.1rem;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  margin-top: 10px;
  text-decoration: none;
  outline: none;
  border: none;
}
/* Loaded CSS */

/* responsive css */
@media(max-width:680px){
  .Newscard{
    height: 400px;
    width: 300px;
    margin: 12px 16px;
    border-radius: 5px;
  }
}
/* Breaks Here */
@media(max-width:665px){
  .Newscard{
    min-width: 90%;
    flex: 1 1;
    margin: 12px 16px;
    padding-bottom: 15px;
    border-radius: 5px;
  }   
  .readmore{
    padding: 8px 16px;
    color: white;
    font-weight: 500;
    margin-left: 1.1rem;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    margin-top: 10px;
    text-decoration: none;
  }
  .Newscard .img-container{
    min-height: 220px;
  }
  .Newscard .img-container img {
    min-height: 220px;
  }
}
/* responsive css */

.Card-Container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 0;
}
#lane4354354 {
  font-size: 3.5rem;
  letter-spacing: 2px;
  color: #444;
  text-transform: capitalize;
  /* background-color: #ff1; */
}
#lane4354354::first-letter,
#News35365654 {
  font-size: 4rem;
  color: #6f00ff;
}
@media (max-width: 420px) {
  #lane4354354 {
    font-size: 2.8rem;
  }
  #lane4354354::first-letter,
  #News35365654 {
    font-size: 3.2rem;
  }
}
/* TaskBar CSS To Get The News From Particular Domain Country or Language*/
.taskbar {
  margin-top: 35px;
  padding: 7px 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.045);
}
.sort {
  background: #fff;
  padding: 11px 25px;
  border-radius: 8px;
  border: 2px solid #6f00ff;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #6f00ff;
  -webkit-user-select: none;
          user-select: none;
}
.sort-choices-container {
  background: #fff;
  overflow: hidden;
  box-shadow: 0 0 6px 1px #6f00ffa8;
  z-index: 12;
  border-radius: 6px;
  position: absolute;
  top: 190px;
  right: 120px;
  min-width: 45vw;
  padding: 25px 21px;
  display: flex;
  justify-content: space-between;
}
.sort-box-container {
  cursor: pointer;
}
.sort-choices-container .column {
  flex: 1 1;
  margin: 0 10px;
}
.sort-choices-container .column1 .the-choices {
  overflow-y: scroll;
  height: 375px;
}
.sort-choices-container .column h1 {
  font-size: 1.8rem;
  color: #6f00ff;
  font-weight: 700;
  margin-bottom: 14px;
}
.sort-choices-container .column p {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  padding: 8px 5px;
  color: #444;
}
.sort-choices-container .column p:hover {
  background-color: #6f00ff4d;
}
.active-choice {
  background-color: #6f00ff4d;
}
.column p{
  margin-bottom: 1px;
}
/* Carat will beshown only when there is "dropdown" */
.sort-choices-container .fa-caret-down {
  display: none;
}
/* Carat will beshown only when there is "dropdown" */

/* TaskBar CSS To Get The News From Particular Domain Country or Language*/

/* responsive css */
@media (max-width: 1040px) {
  .taskbar {
    padding: 5px 30px;
  }
  .sort-choices-container {
    right: 70px;
  }
}
@media (max-width: 700px) {
  .taskbar {
    padding: 8px 8px;
    margin-top: 15px;
  }
  .sort-choices-container {
    right: 20px;
    padding: 18px 10px;
  }
  .sort-choices-container .column {
    height: 300px;
    overflow: scroll;
  }
}
@media (max-width: 425px) {
  .sort-choices-container {
    top: 218px !important;
  }
}
@media (max-width: 600px) {
  .taskbar {
    flex-direction: column;
    align-items: stretch;
  }
  .taskbar .sort-box-container {
    margin-top: 15px;
  }
  .taskbar .sort-box-container .sort {
    font-weight: 700;
    text-align: center;
    padding: 8px 0;
    color: #6f00ff;
  }
  .sort-choices-container {
    top: 240px;
  }
  .sort-choices-container .column {
    flex: 1 1;
    margin: 0 0;
    padding: 0 8px;
    height: 400px;
  }
  .sort-choices-container .column h1 {
    font-size: 1.6rem;
    padding-left: 0.5rem;
  }
  .sort-choices-container .column p {
    font-size: 1rem;
    padding: 6px 3px;
  }

  /* MAnaging The Options in smaller devices*/
  .sort-choices-container {
    right: 9px;
    left:  9px;
    padding: 0;
    max-width: 100vw;
    border-radius: 8px;
    box-shadow: 0 0 5px 1px #6f00ff77;
  }
  .showhide {
    margin-top: 0 !important;
    margin-right: 0;
    position: absolute;
    right: 18px;
    padding-left: 15px !important;
    padding-bottom: 15px !important;
    padding-top: 1px !important;
  }
  .Card-Container {
    padding: 10px 0 40px 0;
  }
  #lane4354354 {
    text-align: center;
  }
  .sort-choices-container {
    flex-direction: column;
    padding: 3px 0;
  }
  .sort-choices-container .column {
    height: unset;
    min-height: 2px;
    max-height: 250px;
  }
  .sort-choices-container .column h1 {
    font-size: 1.2rem;
  }
  .sort-choices-container .column p {
    padding: 5px 5px;
  }
  .sort-choices-container .fa-caret-down {
    display: unset;
  }
}

/* responsive css */

/* Custom Scrollbar*/

.sort-choices-container .column .the-choices::-webkit-scrollbar {
  width: 5px;
}
.sort-choices-container .column .the-choices::-webkit-scrollbar-thumb {
  background: #888;
}
.sort-choices-container .column .the-choices::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Custom Scrollbar*/
.stats-container {
  display: flex;
  padding: 8px 7.3vw;
}
@media (max-width: 600px) {
  .stats-container {
    display: flex;
    justify-content: center;
    padding: 0 0;
  }
}
@media (max-width: 600px) {
  .stats-container {
    display: block;
  }
  #stats {
    font-size: 14px;
    margin-top: 8px;
    text-align: center;
    display: block;
    margin: 15px 0;
  }
}
#autocap {
  text-transform: capitalize;
  margin-right: 16px;
}
.flex {
  display: flex;
}
.hidden {
  display: none;
}
.showhide {
  font-size: 30px;
  margin-top: -7px;
  color: #6f00ff;
  padding: 8px;
  margin-right: -12px;
}
/* Accordion For Smaller Devices */
@media(max-width:600px){ 
  .sort-choices-container{
    padding: 10px 0 0 0 !important;
  }  
  .sort-choices-container .column {
    padding: 4px 0;
  }
  .column1 .the-choices,
  .column2 .the-choices,
  .column3 .the-choices{
    height: unset !important;
    max-height: unset;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 5px;
  }
  .sort-choices-container .column .none{
    display: none !important;
  }
  .sort-choices-container .column .block{
    display: block !important;
    height: 100px !important;
    transition: 5s !important;
  }
}
.news-not-found-error{
    background-color: #f5f6;
    height: 100vh !important;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333;
}
.news-not-found-error h1{
     font-size: 3.8rem;
     margin: 25px 0;
     text-transform: capitalize;
 }
.news-not-found-error h2{
    font-size: 2rem;
    margin: 25px 0;
}
.news-not-found-error .go-back-buttons{
    margin: 45px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}
.news-not-found-error .go-back-buttons a{
    text-decoration: none;
    padding: 10px 18px;
    margin:  0 16px;
    font-weight: 500;
    border-radius: 6px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.103);
    background: #fff;
    color: #ff156f;
}
@media(max-width:450px){
    .news-not-found-error .go-back-buttons a{
        margin: 0 5px;
    }
    .news-not-found-error .go-back-buttons {
        justify-content: space-between;
    }
}
@media(max-width:420px){
    .news-not-found-error .go-back-buttons a{
        margin: 10px 5px;
        width: 100%;
        text-align: center;
    }
    .news-not-found-error .go-back-buttons {
        flex-direction: column;
    }
    .news-not-found-error h1{
        font-size: 3rem;
    }
}
.fa-arrow-up{
    background: #6f00ff;
    padding: 16px;
    border-radius: 50%;
    position: fixed;
    color: white;
    font-size: 28px;
    bottom: 14px;
    right:  14px;
    cursor: pointer;
    box-shadow: 0 15px 25px -10px rgba(0, 0, 0, 0.288);
}
@media(max-width:600px){
    .fa-arrow-up{
        padding: 14px;
        font-size: 24px;
    }
}
.error404-container{
    height: 100vh;
    background: #ff56;
    display: flex;
    justify-content: center;
    align-items: center;
	background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	-webkit-animation: gradientBG 15s ease infinite;
	        animation: gradientBG 15s ease infinite;
}
@-webkit-keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.error404-container .error404-child {
	width: 100%;
    color: #fff;
    font-size: 3.2rem;
    font-weight: 600;
	text-align: center;
}
@media(max-width:750px){
    .error404-container .error404-child {
        font-size: 2.8rem;
        padding: 15px;
    }
}
@media(max-width:450px){
    .error404-container .error404-child {
        font-size: 2.5rem;
        padding: 15px;
    }
}
.context {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10000;
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 5rem;
}
.context a{
    padding: 12px 25px;
    background-color: transparent;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.4rem;
    margin-top: 40px;
    color: white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    width: 165px;
    justify-content: space-between;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.14);
}

.area{
    background: #4e54c8;  
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    width: 100%;
    height:100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    -webkit-animation: animate 25s linear infinite;
            animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 18s;
            animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    -webkit-animation-delay: 15s;
            animation-delay: 15s;
    -webkit-animation-duration: 45s;
            animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 35s;
            animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    -webkit-animation-duration: 11s;
            animation-duration: 11s;
}



@-webkit-keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}



@keyframes animate {

    0%{
        -webkit-transform: translateY(0) rotate(0deg);
                transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        -webkit-transform: translateY(-1000px) rotate(720deg);
                transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
*{
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
